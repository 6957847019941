<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-barley</v-icon>
               <h2>¿Cuántos kilos de cereales (arroz, maíz, etc) consumo por semana?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0">
               <v-text-field
                    v-model="cereales"
                    :error-messages="cerealesErrors"
                    label="Kilos de cereales por semana"
                    outlined
                    @input="setCereales($event)"
                    @blur="$v.cereales.$touch()"
                    inputmode="numeric"
                ></v-text-field>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/personas"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q2"
                    :disabled="$v.$invalid"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      cereales: { required, numeric },
    },

    data() {
        return {
            cereales: ''
        }
    },

    mounted() {
        this.cereales = this.$store.state.cereales
        this.$store.state.progress = 15
        this.goBackIfEmpty()
    },

    computed: {
        cerealesErrors () {
            const errors = []
            if (!this.$v.cereales.$dirty) return errors
            !this.$v.cereales.required && errors.push('Kilos de cereales es requerido.')
            !this.$v.cereales.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setCereales(value) {
            this.$store.state.cereales = value
            this.$v.cereales.$touch()
        },
        goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }
       }
    },
}
</script>